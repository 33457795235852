import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Lock from 'images/homepage/Lock';
import CartDucky2 from 'images/homepage/CartDucky2';
import Counter from './Counter';

const Container = styled.section.attrs({
    className: "container ps-5 pe-5 text-center",
})`
    margin: 120px auto;
`;

const BgContainer = styled.div.attrs({
    className: "d-flex align-items-center pb-3 flex-column flex-md-row justify-content-center",
})``;

const Heading = styled.h1`
    font-style: normal;
    font-weight: 800;
    color: #58bf43 !important;
    margin-bottom: 0;
    font-size: 48px;

    @media (min-width: 992px) {
        font-size: 72px;
    }

    @media (prefers-color-scheme: dark) {
        color: #ffffff !important;
    }
`;

const SubHeading = styled.p`
    margin-top: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;

    @media (min-width: 992px) {
        margin-top: 12px;
        margin-left: 20px
        font-size: 30px;
    }

    @media (min-width: 1200px) {
        font-size: 38px;
    }

    @media (prefers-color-scheme: dark) {
        color: #66d350 !important;
    }
`;

const LockIcon = styled(Lock)`
    margin-bottom: -120px;
    margin-right: 120px;
`;

export default function MemoriesPreserved() {
    const [count, setCount] = useState("");
    const interval = useRef<any>();
    const fetchCount = () =>
        fetch("https://api.ente.io/files/count")
            .then((data) => data.json())
            .then((resp) => {
                const formatter = new Intl.NumberFormat("en-US");
                setCount(resp.count.toString());
            });

    useEffect(() => {
        fetchCount();
        interval.current = setInterval(fetchCount, 10000);

        () => clearInterval(interval.current);
    }, []);

    return (
        <Container>
            <div className="ps-lg-5 pe-lg-5">
                <BgContainer>
                    <div className="d-inline-flex flex-column align-items-center">
                        <LockIcon />
                        <CartDucky2 />
                    </div>
                    <div className="d-inline-flex flex-column align-items-center text-center">
                        <Counter number={count} />
                        <SubHeading>memories preserved</SubHeading>
                    </div>
                </BgContainer>
            </div>
        </Container>
    );
}
